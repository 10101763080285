@keyframes notyf-fadeinup {
  0% {
    opacity: 0;
    transform: translateY(25%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes notyf-fadeinleft {
  0% {
    opacity: 0;
    transform: translateX(25%);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes notyf-fadeoutright {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  to {
    opacity: 0;
    transform: translateX(25%);
  }
}

@keyframes notyf-fadeoutdown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(25%);
  }
}

@keyframes ripple {
  0% {
    transform: scale(0)translateY(-45%)translateX(13%);
  }

  to {
    transform: scale(1)translateY(-45%)translateX(13%);
  }
}

.notyf {
  height: 100%;
  width: 100%;
  color: #fff;
  z-index: 9999;
  pointer-events: none;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 20px;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.notyf__icon--error, .notyf__icon--success {
  height: 21px;
  width: 21px;
  background: #fff;
  border-radius: 50%;
  margin: 0 auto;
  display: block;
  position: relative;
}

.notyf__icon--error:after, .notyf__icon--error:before {
  content: "";
  width: 3px;
  height: 12px;
  background: currentColor;
  border-radius: 3px;
  display: block;
  position: absolute;
  top: 5px;
  left: 9px;
}

.notyf__icon--error:after {
  transform: rotate(-45deg);
}

.notyf__icon--error:before {
  transform: rotate(45deg);
}

.notyf__icon--success:after, .notyf__icon--success:before {
  content: "";
  width: 3px;
  background: currentColor;
  border-radius: 3px;
  display: block;
  position: absolute;
}

.notyf__icon--success:after {
  height: 6px;
  top: 9px;
  left: 6px;
  transform: rotate(-45deg);
}

.notyf__icon--success:before {
  height: 11px;
  top: 5px;
  left: 10px;
  transform: rotate(45deg);
}

.notyf__toast {
  pointer-events: auto;
  max-width: 300px;
  box-sizing: border-box;
  border-radius: 2px;
  flex-shrink: 0;
  padding: 0 15px;
  animation: notyf-fadeinup .3s ease-in forwards;
  display: block;
  position: relative;
  overflow: hidden;
  transform: translateY(25%);
  box-shadow: 0 3px 7px #00000040;
}

.notyf__toast--disappear {
  animation: notyf-fadeoutdown .3s .25s forwards;
  transform: translateY(0);
}

.notyf__toast--disappear .notyf__icon, .notyf__toast--disappear .notyf__message {
  opacity: 1;
  animation: notyf-fadeoutdown .3s forwards;
  transform: translateY(0);
}

.notyf__toast--disappear .notyf__dismiss {
  opacity: 1;
  animation: notyf-fadeoutright .3s forwards;
  transform: translateX(0);
}

.notyf__toast--disappear .notyf__message {
  animation-delay: 50ms;
}

.notyf__toast--upper {
  margin-bottom: 20px;
}

.notyf__toast--lower {
  margin-top: 20px;
}

.notyf__toast--dismissible .notyf__wrapper {
  padding-right: 30px;
}

.notyf__ripple {
  height: 400px;
  width: 400px;
  transform-origin: 100% 100%;
  z-index: 5;
  border-radius: 50%;
  animation: ripple .4s ease-out forwards;
  position: absolute;
  top: 0;
  right: 0;
  transform: scale(0)translateY(-51%)translateX(13%);
}

.notyf__wrapper {
  z-index: 10;
  border-radius: 3px;
  align-items: center;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-right: 15px;
  display: flex;
  position: relative;
}

.notyf__icon {
  width: 22px;
  text-align: center;
  opacity: 0;
  margin-right: 13px;
  font-size: 1.3em;
  animation: notyf-fadeinup .3s .3s forwards;
}

.notyf__dismiss {
  height: 100%;
  width: 26px;
  opacity: 0;
  margin-right: -15px;
  animation: notyf-fadeinleft .3s .35s forwards;
  position: absolute;
  top: 0;
  right: 0;
}

.notyf__dismiss-btn {
  cursor: pointer;
  opacity: .35;
  height: 100%;
  width: 100%;
  background-color: #00000040;
  border: none;
  outline: none;
  transition: opacity .2s, background-color .2s;
}

.notyf__dismiss-btn:after, .notyf__dismiss-btn:before {
  content: "";
  height: 12px;
  width: 2px;
  background: #fff;
  border-radius: 3px;
  position: absolute;
  top: calc(50% - 5px);
  left: calc(50% - 1px);
}

.notyf__dismiss-btn:after {
  transform: rotate(-45deg);
}

.notyf__dismiss-btn:before {
  transform: rotate(45deg);
}

.notyf__dismiss-btn:hover {
  opacity: .7;
  background-color: #00000026;
}

.notyf__dismiss-btn:active {
  opacity: .8;
}

.notyf__message {
  vertical-align: middle;
  opacity: 0;
  line-height: 1.5em;
  animation: notyf-fadeinup .3s .25s forwards;
  position: relative;
}

@media only screen and (max-width: 480px) {
  .notyf {
    padding: 0;
  }

  .notyf__ripple {
    height: 600px;
    width: 600px;
    animation-duration: .5s;
  }

  .notyf__toast {
    max-width: none;
    width: 100%;
    border-radius: 0;
    box-shadow: 0 -2px 7px #00000021;
  }

  .notyf__dismiss {
    width: 56px;
  }
}

.szh-menu {
  box-sizing: border-box;
  width: max-content;
  z-index: 100;
  background-color: #fff;
  border: 1px solid #0000001a;
  margin: 0;
  padding: 0;
  list-style: none;
}

.szh-menu:focus {
  outline: none;
}

.szh-menu__arrow {
  box-sizing: border-box;
  width: .75rem;
  height: .75rem;
  z-index: -1;
  background-color: #fff;
  border: 1px solid #0000;
  border-color: #0000001a #0000 #0000 #0000001a;
}

.szh-menu__arrow--dir-left {
  right: -.375rem;
  transform: translateY(-50%)rotate(135deg);
}

.szh-menu__arrow--dir-right {
  left: -.375rem;
  transform: translateY(-50%)rotate(-45deg);
}

.szh-menu__arrow--dir-top {
  bottom: -.375rem;
  transform: translateX(-50%)rotate(-135deg);
}

.szh-menu__arrow--dir-bottom {
  top: -.375rem;
  transform: translateX(-50%)rotate(45deg);
}

.szh-menu__item {
  cursor: pointer;
}

.szh-menu__item:focus {
  outline: none;
}

.szh-menu__item--hover {
  background-color: #ebebeb;
}

.szh-menu__item--focusable {
  cursor: default;
  background-color: inherit;
}

.szh-menu__item--disabled {
  cursor: default;
  color: #aaa;
}

.szh-menu__group {
  box-sizing: border-box;
}

.szh-menu__radio-group {
  margin: 0;
  padding: 0;
  list-style: none;
}

.szh-menu__divider {
  height: 1px;
  background-color: #0000001f;
  margin: .5rem 0;
}

.szh-menu-button {
  box-sizing: border-box;
}

.szh-menu {
  user-select: none;
  color: #212529;
  min-width: 10rem;
  border: none;
  border-radius: .25rem;
  padding: .5rem 0;
  box-shadow: 0 3px 7px #0002, 0 .6px 2px #0000001a;
}

.szh-menu__item {
  align-items: center;
  padding: .375rem 1.5rem;
  display: flex;
  position: relative;
}

.szh-menu-container--itemTransition .szh-menu__item {
  transition-property: background-color, color;
  transition-duration: .15s;
  transition-timing-function: ease-in-out;
}

.szh-menu__item--type-radio {
  padding-left: 2.2rem;
}

.szh-menu__item--type-radio:before {
  content: "○";
  font-size: .8rem;
  position: absolute;
  top: .55rem;
  left: .8rem;
}

.szh-menu__item--type-radio.szh-menu__item--checked:before {
  content: "●";
}

.szh-menu__item--type-checkbox {
  padding-left: 2.2rem;
}

.szh-menu__item--type-checkbox:before {
  position: absolute;
  left: .8rem;
}

.szh-menu__item--type-checkbox.szh-menu__item--checked:before {
  content: "✔";
}

.szh-menu__submenu > .szh-menu__item {
  padding-right: 2.5rem;
}

.szh-menu__submenu > .szh-menu__item:after {
  content: "❯";
  position: absolute;
  right: 1rem;
}

.szh-menu__header {
  color: #888;
  text-transform: uppercase;
  padding: .2rem 1.5rem;
  font-size: .8rem;
}

@keyframes szh-menu-show-slide-left {
  from {
    opacity: 0;
    transform: translateX(.75rem);
  }
}

@keyframes szh-menu-hide-slide-left {
  to {
    opacity: 0;
    transform: translateX(.75rem);
  }
}

@keyframes szh-menu-show-slide-right {
  from {
    opacity: 0;
    transform: translateX(-.75rem);
  }
}

@keyframes szh-menu-hide-slide-right {
  to {
    opacity: 0;
    transform: translateX(-.75rem);
  }
}

@keyframes szh-menu-show-slide-top {
  from {
    opacity: 0;
    transform: translateY(.75rem);
  }
}

@keyframes szh-menu-hide-slide-top {
  to {
    opacity: 0;
    transform: translateY(.75rem);
  }
}

@keyframes szh-menu-show-slide-bottom {
  from {
    opacity: 0;
    transform: translateY(-.75rem);
  }
}

@keyframes szh-menu-hide-slide-bottom {
  to {
    opacity: 0;
    transform: translateY(-.75rem);
  }
}

.szh-menu--state-opening.szh-menu--dir-left {
  animation: szh-menu-show-slide-left .15s ease-out;
}

.szh-menu--state-closing.szh-menu--dir-left {
  animation: szh-menu-hide-slide-left .15s ease-in forwards;
}

.szh-menu--state-opening.szh-menu--dir-right {
  animation: szh-menu-show-slide-right .15s ease-out;
}

.szh-menu--state-closing.szh-menu--dir-right {
  animation: szh-menu-hide-slide-right .15s ease-in forwards;
}

.szh-menu--state-opening.szh-menu--dir-top {
  animation: szh-menu-show-slide-top .15s ease-out;
}

.szh-menu--state-closing.szh-menu--dir-top {
  animation: szh-menu-hide-slide-top .15s ease-in forwards;
}

.szh-menu--state-opening.szh-menu--dir-bottom {
  animation: szh-menu-show-slide-bottom .15s ease-out;
}

.szh-menu--state-closing.szh-menu--dir-bottom {
  animation: szh-menu-hide-slide-bottom .15s ease-in forwards;
}

/*# sourceMappingURL=index.3c393cd7.css.map */
